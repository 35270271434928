import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import {
  normalizeArrayOfPaymentInfoVideos,
  normalizePaymentInfoChannel,
} from 'shared/redux/helpers/normalized';
import { getMemberInfo } from 'api/public/channel/channel';
import { getHydratedData } from '../../../client/hydrated-data/hydrated-data';
import { isSiteMode } from 'widget/selectors/view-mode';
import { createAsyncProxy } from 'shared/worker/lib';

const ACTIONS = createAsyncActions('SERVER.CHANNEL.PAYMENT');
export const NAMES = ACTIONS.NAMES;
const { START, SUCCESS, FAIL } = ACTIONS;

export const getPaymentInfo = createAsyncProxy(
  'payment.getPaymentInfo',
  function(id, options = {}) {
    return function(dispatch, getState, { withContext }) {
      const state = getState();
      const isSite = isSiteMode(state);
      const { uid, instance, memberInfo: hydratedMemberInfo } = getHydratedData(
        state,
      );

      if (!uid || !isSite) {
        return Promise.resolve().then(() => dispatch(SUCCESS()));
      }

      const {
        channelVideos: { ids: items },
      } = state;
      options = {
        instance,
        ...options,
        items,
      };

      const params = { id, options };
      dispatch(START(params));

      return withContext(getMemberInfo)(id, instance, {
        hydratedMemberInfo,
      }).then(
        function(response) {
          const items = _.get(response, 'data.items', []);

          if (!items.length) {
            dispatch(SUCCESS(params, response));
            return response;
          }

          const [channelData, videoDataList] = _.partition(
            items,
            item => !item.itemId,
          );

          const channel = _.get(channelData, '[0]');
          const videos = _.map(videoDataList, video =>
            _.pick(video, 'dgsInfo', 'itemId', 'listId'),
          );

          let normalizeChannel = {};

          if (channel) {
            normalizeChannel = {
              ...normalizePaymentInfoChannel(
                _.pick(channel, 'dgsInfo', 'listId'),
              ),
            };
          }

          /**
           * We are getting channel and video ids from backend in form 75597969-d0e0fda03fb64c7bbed8746046bff110,
           * where 75597969 - publish id, d0e0fda03fb64c7bbed8746046bff110 - item id.
           * This id is stored in itemId property.
           * But when we receive dgsInfo from member info - we have simple id in above mentioned
           * property.
           * So we extracting only dgsInfo, dropping itemId.
           */

          const { result, entities } = normalizeArrayOfPaymentInfoVideos(
            videos,
          );

          const dgsInfo = _.reduce(
            entities.videos,
            (acc, { dgsInfo }, key) => ({
              ...acc,
              [key]: { dgsInfo },
            }),
            {},
          );

          response = {
            ...response,
            data: _.merge({}, normalizeChannel, {
              result,
              entities: {
                ...entities,
                videos: dgsInfo,
              },
            }),
          };

          dispatch(SUCCESS(params, response));
          return response;
        },

        function(reason) {
          const meta = {
            analytics: { type: 'error', name: 'shared.channel.payment.get' },
          };

          if (_.isError(reason)) {
            dispatch(FAIL(reason, null, meta));
          } else {
            dispatch(FAIL(params, reason, meta));
          }

          return Promise.reject(reason);
        },
      );
    };
  },
);
