import React from 'react';
import _ from 'lodash';
import PAYMENT_MESSAGE_PROXY from 'shared/constants/payment-message-proxy';

// TODO: consider removing
export default class PaymentEventProxy extends React.Component {
  componentDidMount() {
    window.addEventListener('message', this.proxyEvent, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.proxyEvent, false);
  }

  proxyEvent(event) {
    if (_.get(event, 'data.completed', false)) {
      for (let i = 0; i < window.parent.frames.length; i += 1) {
        const frame = window.parent.frames[i];
        frame.postMessage(
          {
            id: PAYMENT_MESSAGE_PROXY.ID,
            message: event.data,
          },
          window.location.origin,
        );
      }
    }
  }

  render() {
    return null;
  }
}
