export default {
  DASHBOARD: {
    CLOSED: 'vod.dashboard.closed',
  },
  MODAL: {
    OPEN: 'vod.modal.open',
    CLOSE: 'vod.modal.close',
  },
  TRANSCODING: {
    READY: 'vod.transcoding.ready',
  },
  TOOLTIP: {
    SHOW: 'vod.tooltip.show',
    HIDE: 'vod.tooltip.hide',
    DID_SHOW: 'vod.tooltip.did-show',
    DID_HIDE: 'vod.tooltip.did-hide',
  },
  POPOUT: {
    SHOW: 'vod.popout.show',
    HIDE: 'vod.popout.hide',
  },
  PAYMENT: {
    PAYMENT_SUCCESS: 'vod.payment.payment-success',
    CANCEL_SUBSCRIPTION: 'vod.payment.cancel-subscription',
  },
  SESSION: {
    LOGOUT_USER: 'vod.session.logout-user',
  },
};
