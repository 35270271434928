import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { getAppLocale } from 'services/locale/locale';

const asAxiosResponse = data => (data ? { data } : null);

const parseServer = source => {
  const locale = source.__LOCALE__;
  const translations = source.__TRANSLATIONS__;
  const singleVideo = source.__SINGLE_VIDEO__;
  const channelVideos = source.__VIDEOS__;
  const memberInfo = source.__MEMBER_INFO__;
  const viewMode = source.viewMode;
  const currentSiteUser = source.__CURRENT_SITE_USER__;
  const metaSiteId = source.metaSiteId;

  return {
    biToken: source.biToken,
    experiments: source.__EXPERIMENTS__,
    migrationInfo: source.__MIGRATION_INFO__,
    windowSize: source.windowSize,
    metaSiteId,
    locale,
    translations,
    siteUrl: source.__SITE_URL__,
    staticsVersion: source.staticsVersion || __CI_APP_VERSION__,
    editorType: source.__EDITOR_TYPE__,
    channel: asAxiosResponse(source.__CHANNEL__),
    channelVideos: asAxiosResponse(channelVideos),
    memberInfo: asAxiosResponse(memberInfo),
    singleVideo: asAxiosResponse(singleVideo),
    singleVideoId: singleVideo && singleVideo.base_item_id,
    instance: source.instance,
    instanceId: source.instanceId,
    viewMode,
    uid: source.uid,
    compId: source.compId,
    currentSiteUser: viewMode === VIEW_MODES.SITE ? currentSiteUser : null,
    deviceType: source.deviceType,
  };
};

const parseClient = source => {
  const serverResult = parseServer(source);
  const { translations } = serverResult;
  const {
    currentPageId,
    siteLocale,
    siteOwnerId,
    fullSiteUrl,
    appSettings,
  } = source;

  const isRTLExperimentEnabled =
    source.__EXPERIMENTS__['specs.vod.rtl'] === 'true';
  const locale =
    serverResult.locale ||
    getAppLocale({ appSettings, isRTLExperimentEnabled, siteLocale });

  return {
    ...serverResult,
    currentPageId,
    locale,
    translations,
    siteOwnerId,
    fullSiteUrl,
  };
};

export const parseHydratedData = source => {
  return parseClient(source);
};
